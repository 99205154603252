<template>
  <div class="bg-html">
    <Header />
    <main class="w-75 mx-auto mt-4 mb-4">
      <div class="min-heigth-61vh">
        <b-row>
          <b-col cols="12">
            <ProfileLine title="Editar Configuração" :items="profileItems" />
          </b-col>
        </b-row>
        <b-form @submit.stop.prevent="submit">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Descrição">
                <b-form-input
                  disabled
                  v-model="form.description"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Value">
                <quill-editor
                  v-model="form.value"
                  ref="refQuillEditor"
                  :options="quillOptions"
                >
                </quill-editor>
              </b-form-group>
            </b-col>
            <b-col cols="12" >
              <div class="mt-3 mx-auto container-button">
                <b-button variant="secondary" class="mr-2" @click="back">
                  Voltar
                </b-button>
                <b-button variant="info" @click="submit">
                  Enviar
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
       </div>
    </main>
    <Footer />
    <div class="spinner" v-if="spinner">
      <Spinner class="spinner-item" />
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'

import ConfigurationService from '@/service/ConfigurationService'

import Header from '@/components/Header'
import Footer from '@/components/Footer'
import ProfileLine from '@/components/ProfileLine'
import Spinner from '@/components/Spinner'

export default {
  components: { Header, Footer, ProfileLine, Spinner },

  data () {
    return {
      profileItems: [
        {
          text: 'Admin',
          href: '/admin'
        },
        {
          text: 'Configuraçções',
          href: '/admin/configuration'
        },
        {
          text: 'Item',
          active: true
        }
      ],
      quillOptions: {
        placeholder: 'Digite o conteúdo',
        readonly: false,
        size: [ 'small', false, 'large', 'huge' ]
      },
      configurationService: null,
      spinner: false,
      form: {},
    }
  },
  methods: {
    get (id) {
      this.spinner = true
      this.configurationService.get(id)
        .then(response => {
          this.form = response
          this.spinner = false
        })
    },
    back () {
      this.$router.push(`/admin/configuration`) 
    },
    submit () {
      this.spinner = true
      this.configurationService.update(this.form.id, this.form.value)
        .then(response => {
          if (response) {
            Swal.fire({
              title: 'Sucesso',
              text: 'Configuração Salva com sucesso',
              icon: 'success'
            }).then(() => {
              this.spinner = true
              this.$router.push(`/admin/configuration`) 
            })
          } else {
            Swal.fire({
              title: 'Erro',
              text: 'Erro Inesperado, Tente novamente',
              icon: 'error'
            })
          }
        })
        .finally(() => {
          this.spinner = false
        })
    }
  },
  mounted () {
    this.configurationService = new ConfigurationService()
    this.get(this.$route.params.id)
  }
}
</script>

<style>
  .min-heigth-61vh {
    min-height: 61vh;
  }

  body {
    background: #F5F5F5;
  }

  .container-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
</style>