import { apiUrl } from '@/config/global'

export default class ConfigurationService{
  constructor(http){
    this._http = http
  }

  getAll () {
    return fetch(`${apiUrl}/api/configuration`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('auth')}`
      },
    }).then(response => response.json())
  }

  getAllPartnerDiscount () {
    return fetch(`${apiUrl}/api/configuration/partnerDiscount`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('auth')}`
      },
    }).then(response => response.json())
  }

  get (id) {
    return fetch(`${apiUrl}/api/configuration/${id}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('auth')}`
      },
    }).then(response => response.json())
  }

  update (id, value) {
    const json = {
      id,
      value
    }

    return fetch(`${apiUrl}/api/configuration`, {
      method: 'put',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('auth')}`
      },
      body: JSON.stringify(json)
    }).then(response => response.json())
  }
}